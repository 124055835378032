import { React, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import moment from 'moment';

import SearchInput from './SearchInput';
import SelectStatusBox from './SelectStatusBox';
import SelectGenderBox from './SelectGenderBox';
import SelectAbsenceBox from './SelectAbsenceBox';
import SelectRemainDayBox from './SelectRemainDayBox';
import SortingModule from '../../../Model/Share/Normal/SortingModule';
import UserProfile from '../../../Component/Normal/UserProfile';

import NormalSortButtonPath from '../../../image/GrayNormalSortButton.png';
import AscSortButtonPath from '../../../image/AscSortButton.png';
import DescSortButtonPath from '../../../image/DescSortButton.png';
import DefaultProfile from '../../../image/DefaultProfile.png';
import CheckImagePath from '../../../image/Check.png';

export default function SendMemberSelectModal(props) {
  const { memberList, onClickAdditionButton, onClickExit } = props;

  const [members, setMembers] = useState([]);
  const [originalSortedMembers, setOriginalSortedMembers] = useState([]);
  const [sortedMembers, setSortedMembers] = useState([]);
  const [searchValue, setSearchValue] = useState('');

  const [isClickedValidSorting, setIsClickedValidSorting] = useState(false);
  const [isClickedInvalidSorting, setIsClickedInvalidSorting] = useState(false);

  const [isClickedMaleSorting, setIsClickedMaleSorting] = useState(false);
  const [isClickedFemaleSorting, setIsClickedFemaleSorting] = useState(false);

  const [absenceStart, setAbsenceStart] = useState(0);
  const [absenceEnd, setAbsenceEnd] = useState(0);

  const [remainDayStart, setRemainDayStart] = useState(0);
  const [remainDayEnd, setRemainDayEnd] = useState(0);

  const [startSort, setStartSort] = useState('normal');
  const [endSort, setEndSort] = useState('desc');

  const [startButtonImg, setStartButtonImg] = useState(AscSortButtonPath);
  const [endButtonImg, setEndButtonImg] = useState(AscSortButtonPath);

  const [isClickedAllMemberSelect, setIsClickedAllMemberSelect] = useState(false);
  const [nowPage, setNowPage] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  // 스크롤 제어 //
  useEffect(() => {
    document.body.style = `overflow: hidden`;
    return () => document.body.style = `overflow: auto`;
  }, [])

  // 회원 선택 체크 변수 처리 //
  useEffect(() => {
    let newMembers = memberList.slice();
    let count = 0;
    // newMembers.map((data) => data.isClicked = false);
    newMembers.map((data) => {
      if (data.isClicked) count += 1;
    });

    if (count === newMembers.length) setIsClickedAllMemberSelect(true);
    else setIsClickedAllMemberSelect(false);

    // newMembers.sort((a, b) => b.endDate - .endDate);
    // newMembers.sort((a, b) => moment(b.endDate).isBefore(a.endDate));
    newMembers = SortingModule.dataSort(newMembers, 'endDate', 'desc', 'endDate');

    // console.log(newMembers);
    setMembers(newMembers);
    setOriginalSortedMembers(newMembers);
  }, [memberList])

  // 회원정보 정렬 //
  useEffect(() => {
    if (members.length === 0) setSortedMembers([]);
    else {
      let newMembers = [];

      // 유효, 만료 회원 추출 데이터 //
      let validCheckMembers = [];

      // 유효회원 둘다 체크되어있거나 둘다 체크하지 않은 경우 //
      if ((isClickedValidSorting && isClickedInvalidSorting)
        || (!isClickedValidSorting && !isClickedInvalidSorting)) {
          validCheckMembers = members;
      }
      // 유효회원만 체크되어있는 경우 //
      else if (isClickedValidSorting) {
        members.map((data) => {
          if (moment().isBefore(moment(data.endDate))
            && (data.totalMax === -1 || data.totalMax > data.reserveCount)) {
              validCheckMembers.push(data);
          }
        })
      }
      // 만료회원만 체크되어있는 경우 //
      else if (isClickedInvalidSorting) {
        members.map((data) => {
          if (moment().isAfter(moment(data.endDate))
            || data.totalMax <= data.reserveCount) {
              validCheckMembers.push(data);
          }
        })
      }

      // 성별구분 추출 데이터 //
      let genderCheckMembers = [];
      
      // 성별 둘다 체크되어있거나 둘다 체크하지 않은 경우//
      if ((isClickedFemaleSorting && isClickedMaleSorting)
        || (!isClickedFemaleSorting && !isClickedMaleSorting)) {
        genderCheckMembers = validCheckMembers;
      }
      // 여성만 체크된 경우 //
      else if (isClickedFemaleSorting) {
        validCheckMembers.map((data) => {
          if (data.gender === 'female') genderCheckMembers.push(data);
        })
      }
      // 남성만 체크된 경우 //
      else if (isClickedMaleSorting) {
        validCheckMembers.map((data) => {
          if (data.gender === 'male') genderCheckMembers.push(data);
        })
      }

      // 미출석 기간 추출 데이터 //
      let absenceCheckMembers = [];

      // 미출석 기간설정이 활성화 되지 않은 경우 //
      if (absenceStart === 0 && absenceEnd === 0) {
        absenceCheckMembers = genderCheckMembers;
      }
      // 활성화 되어있는경우 //
      else {
        genderCheckMembers.map((data) => {
          if (data.reserves.length > 0) {
            let classDate = moment(data.reserves[data.reserves.length - 1].classDate);

            if (moment().diff(classDate, 'days') >= absenceStart
            && moment().diff(classDate, 'days') <= absenceEnd) {
              absenceCheckMembers.push(data);
            }
          }
        })
      }

      // 잔여 일수 추출 데이터 //
      let remainDayMembers = [];

      // 미출석 기간설정이 활성화 되지 않은 경우 //
      if (remainDayStart === 0 && remainDayEnd === 0) {
        remainDayMembers = absenceCheckMembers;
      }
      // 활성화 되어있는경우 //
      else {
        absenceCheckMembers.map((data) => {
          let endDate = moment(data.endDate);

          if (moment().diff(endDate, 'days') >= remainDayStart
          && moment().diff(endDate, 'days') <= remainDayEnd) {
            remainDayMembers.push(data);
          }
        })
      }


      if (startSort === 'asc') remainDayMembers = SortingModule.dataSort(remainDayMembers, 'startDate', 'asc', 'startDate');
      else if (startSort === 'desc') remainDayMembers = SortingModule.dataSort(remainDayMembers, 'startDate', 'desc', 'startDate');
      else if (endSort === 'asc') remainDayMembers = SortingModule.dataSort(remainDayMembers, 'endDate', 'asc', 'endDate');
      else if (endSort === 'desc') remainDayMembers = SortingModule.dataSort(remainDayMembers, 'endDate', 'desc', 'endDate');

      let nameList = [];
      let phoneList = [];
  
      for (let i = 0; i < remainDayMembers.length; i++) {
        if (remainDayMembers[i].name.toLowerCase().includes(searchValue.toLowerCase())) nameList.push(remainDayMembers[i]);
        else if (remainDayMembers[i].phone.includes(searchValue)) phoneList.push(remainDayMembers[i]);
      }
  
      if (nameList.length === 0 && phoneList.length === 0) {
        for (let i = 0; i < remainDayMembers.length; i++) {
          if (match(searchValue.toLowerCase(), remainDayMembers[i].name.toLowerCase())) nameList.push(remainDayMembers[i]);
        }
      }
  
      let totalList = nameList.concat(phoneList);
      
      if (totalList.length > 10) {
        let finalPage = parseInt(totalList.length / 10);

        if (nowPage > finalPage) setNowPage(finalPage);

        setTotalPage(finalPage);
      }
      else {
        setNowPage(0);
        setTotalPage(1);
      }

      // 페이지 분리 //
      if (totalList.length !== 0) {
        if (totalList.length > 10) newMembers = totalList.slice((nowPage * 10), (nowPage * 10) + 10);
        else newMembers = totalList.slice();
      }
  
      // console.log(newMembers);
      // setOriginalSortedMembers(totalList);
      setSortedMembers(newMembers);
    }
  }, 
  [
    members,
    searchValue, 
    isClickedValidSorting, 
    isClickedInvalidSorting,
    isClickedMaleSorting,
    isClickedFemaleSorting,
    absenceStart,
    absenceEnd,
    remainDayStart,
    remainDayEnd,
    startSort,
    endSort,
    nowPage,
    match()
  ])

  // 시작일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (startSort === 'normal') setStartButtonImg(NormalSortButtonPath);
    else if (startSort === 'asc') setStartButtonImg(AscSortButtonPath);
    else if (startSort === 'desc') setStartButtonImg(DescSortButtonPath);
  }, [startSort])

  // 종료일 버튼 클릭시 이미지 변경 //
  useEffect(() => {
    if (endSort === 'normal') setEndButtonImg(NormalSortButtonPath);
    else if (endSort === 'asc') setEndButtonImg(AscSortButtonPath);
    else if (endSort === 'desc') setEndButtonImg(DescSortButtonPath);
  }, [endSort])

  // 몇명 선택되었는지 텍스트 표출 //
  function decideSelectedCountText() {
    if (isClickedAllMemberSelect) return originalSortedMembers.length + ' 명';
    else {
      let count = 0;

      originalSortedMembers.map((data) => {
        if (data.isClicked) count += 1;
      })

      return count + ' 명';
    }
  }

  // 입력된 글자가 한글인지 확인 //
  function is_kr(value) {
    if (value === undefined) return false;
    
    return ((('ㄱ'.charCodeAt() <= value.charCodeAt()) && (value.charCodeAt() <= 'ㅎ'.charCodeAt()))
            || (('가'.charCodeAt() <= value.charCodeAt()) && value.charCodeAt() <= '힣'.charCodeAt()));
  }

  // 한글 글자 유니코드 추출하여 검색 조건 확인 //
  function kr_num(value) {
    return value.charCodeAt() - '가'.charCodeAt();
  }

  // 초성 중성 종성 구분하여 연산 //
  function kr_list(value) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var res = [value];

    var kr_num_s = kr_num(value);

    if (kr_num_s > 0) {
      // 초성 + 중성일 경우 //
      if (kr_num_s % 28 !== 0) {
        res.push(String.fromCharCode(((parseInt(kr_num_s / 28) * 28) + '가'.charCodeAt())));
      }
      
      res.push(consonant_list[parseInt(kr_num_s / 588)])
    }

    return res;
  }

  // 한글, 영어 구분하여 검색 처리 //
  function eq_kr(value, data) {
    if (is_kr(value) && is_kr(data)) {
      return kr_list(data).includes(value);
    }
    else return value === data;
  }

  // 받침 확인하기 //
  function eq_kr_pos(value, data, nextData) {
    var consonant_list = ['ㄱ', 'ㄲ', 'ㄴ', 'ㄷ', 'ㄸ', 'ㄹ', 'ㅁ', 'ㅂ', 'ㅃ', 'ㅅ'
                        , 'ㅆ', 'ㅇ', 'ㅈ', 'ㅉ', 'ㅊ', 'ㅋ', 'ㅌ', 'ㅍ', 'ㅎ'];

    var kr_pos = [
      [0, 0], [1, 0], [2, 1], [2, 9], [4, 2], [1, 12], [2, 18],
      [7, 3], [8, 5], [1, 0], [2, 6], [3, 7], [4, 9], [5, 16],
      [6, 17], [7, 18], [16, 6], [17, 7], [1, 9], [19, 9], [20, 10],
      [21, 11], [22, 12], [23, 14], [24, 15], [25, 16], [26, 17], [27, 18]
    ]

    if (!(is_kr(value) && is_kr(data) && is_kr(nextData))) return false;
    if (kr_num(value) < 0) return false;
    
    var s_pos = String.fromCharCode(value.charCodeAt() - kr_pos[kr_num(value) % 28][0])
    var s_pos_consonant = consonant_list[kr_pos[kr_num(value) % 28][1]]

    return (s_pos === data) && (kr_list(nextData).includes(s_pos_consonant));
  }

  // 일치하는 글자가 존재하는지 확인 //
  function match(value, data) {
    if (value === undefined || data === undefined) return false;

    var valueSize = value.length;
    var dataSize = data.length;

    if (dataSize < valueSize) return false;

    for (var i = 0; i < (dataSize - valueSize + 1); i++) {
      var res = true;

      for (var j = 0; j < valueSize; j++) {
        if (j === (valueSize - 1)) {
          if (!eq_kr(value[j], data[i + j])) {
            if (i + j + 1 < dataSize) {
              if (eq_kr_pos(value[j], data[i + j], data[i + j + 1])) {
                break;
              }
            }
            res = false;
            break;
          }
        }
        else {
          if (value[j] !== data[i + j]) {
            res = false;
            break;
          }
        }
      }

      if (res) return true;
    }
    
    return false;
  }

  // 필터 초기화 클릭시 //
  function onClickResetFilter() {

    let newOriginalSortedMembers = originalSortedMembers.slice();

    newOriginalSortedMembers.map((data) => data.isClicked = false);

    setOriginalSortedMembers(newOriginalSortedMembers);

    setSearchValue('');
    setIsClickedValidSorting(false);
    setIsClickedInvalidSorting(false);
    setIsClickedMaleSorting(false);
    setIsClickedFemaleSorting(false);
    setIsClickedAllMemberSelect(false);
    setAbsenceStart(0);
    setAbsenceEnd(0);
    setRemainDayStart(0);
    setRemainDayEnd(0);
    setStartSort('normal');
    setEndSort('desc');
  }

  // 시작일 정렬 버튼 클릭시 //
  function onClickStartSort() {
    setEndSort('normal');

    if (startSort === 'normal' || startSort === 'desc') setStartSort('asc');
    else if (startSort === 'asc') setStartSort('desc');
  }

  // 종료일 정렬 버튼 클릭시 //
  function onClickEndSort() {
    setStartSort('normal');

    if (endSort === 'normal' || endSort === 'desc') setEndSort('asc');
    else if (endSort === 'asc') setEndSort('desc');
  }

  // 전체선택 클릭시 //
  function onClickAllSelectButton() {
    let newOriginalSortedMembers = originalSortedMembers.slice();

    newOriginalSortedMembers.map((data) => data.isClicked = !isClickedAllMemberSelect);

    setOriginalSortedMembers(newOriginalSortedMembers);
    setIsClickedAllMemberSelect(!isClickedAllMemberSelect);
  }

  // 페이지네이션 이전버튼 클릭시 //
  function onClickPrevButton() {
    if (nowPage !== 0) {
      setNowPage(nowPage - 1);
    }
  }

  // 페이지네이션 다음버튼 클릭시 //
  function onClickNextButton() {
    if ((nowPage + 1) !== totalPage) {
      setNowPage(nowPage + 1);
    }
  }

  // 회원 선택시 //
  function onClickMemberInfo(data) {
    let newOriginalSortedMembers = originalSortedMembers.slice();
    let index = newOriginalSortedMembers.findIndex(item => item.memberId === data.memberId);

    if (newOriginalSortedMembers[index].isClicked) setIsClickedAllMemberSelect(false);

    newOriginalSortedMembers[index].isClicked = !newOriginalSortedMembers[index].isClicked;

    setOriginalSortedMembers(newOriginalSortedMembers);
  }

  // 회원 선택목록에서 제외버튼 클릭시 //
  function onClickDeleteButton(data) {
    let newOriginalSortedMembers = originalSortedMembers.slice();
    let index = newOriginalSortedMembers.findIndex(item => item.memberId === data.memberId);

    if (newOriginalSortedMembers[index].isClicked) setIsClickedAllMemberSelect(false);

    newOriginalSortedMembers[index].isClicked = !newOriginalSortedMembers[index].isClicked;

    setOriginalSortedMembers(newOriginalSortedMembers);
  }

  // 회원 추가하기 버튼 클릭시 //
  function onClickAddition() {
    // () => onClickAdditionButton(originalSortedMembers)
    let members = originalSortedMembers.slice();
    let selectedMembers = [];

    members.map((data) => {
      if (data.isClicked) selectedMembers.push(data);
    })

    onClickAdditionButton(selectedMembers);
  }

  return (
    <TopLevelWrapper>
      <Modal>
        <ModalInnerWrapper>
          <TopLineWrapper>
            <TitleWrapper>
              <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#232323' hover='default'>문자 발송</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#777777' hover='default'>일정, 회원관리 등을 공지하고 확인할 수 있어요</Text>
            </TitleWrapper>
            <ButtonWrapper>
              <ExitButton onClick={onClickExit}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#3E4148' hover='pointer'>취소</Text>
              </ExitButton>
              <AdditionButton onClick={onClickAddition}>
                <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#FFFFFF' hover='pointer'>추가하기</Text>
              </AdditionButton>
            </ButtonWrapper>
          </TopLineWrapper>
          <SearchInputWrapper>
            <SearchInput value={searchValue} setValue={setSearchValue} placeholder='회원 검색' />
          </SearchInputWrapper>
          <ResetAndSortingButtonWrapper>
            <SortingButtonWrapper>
              <SelectStatusBox
                isClickedValidSorting={isClickedValidSorting}
                isClickedInvalidSorting={isClickedInvalidSorting}
                setIsClickedValidSorting={setIsClickedValidSorting}
                setIsClickedInvalidSorting={setIsClickedInvalidSorting} />

              <SelectGenderBox
                isClickedMaleSorting={isClickedMaleSorting}
                isClickedFemaleSorting={isClickedFemaleSorting}
                setIsClickedMaleSorting={setIsClickedMaleSorting}
                setIsClickedFemaleSorting={setIsClickedFemaleSorting} />

              <SelectAbsenceBox
                absenceStart={absenceStart}
                absenceEnd={absenceEnd}
                setAbsenceStart={setAbsenceStart}
                setAbsenceEnd={setAbsenceEnd} />

              <SelectRemainDayBox
                remainDayStart={remainDayStart}
                remainDayEnd={remainDayEnd}
                setRemainDayStart={setRemainDayStart}
                setRemainDayEnd={setRemainDayEnd} />

            </SortingButtonWrapper>
            <FilterResetButton onClick={onClickResetFilter}>
              <Text fontFamily='NotoSansKR-Regular' fontSize={12} color='#3E4148' hover='pointer'>필터 초기화</Text>
            </FilterResetButton>
          </ResetAndSortingButtonWrapper>

          <TotalMemberBoxWrapper>
            <TotalMemberBox>
              <TotalMemberColumnWrppaer>
                <MemberCheckBox isClicked={isClickedAllMemberSelect} onClick={onClickAllSelectButton}>
                  { isClickedAllMemberSelect && <CheckImage src={CheckImagePath}/> }
                </MemberCheckBox>
                <TextWrapper marginLeft={12} width={60}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>이름</Text>
                </TextWrapper>
                <TextWrapper marginLeft={4} width={50}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>성별</Text>
                </TextWrapper>
                <TextWrapper marginLeft={4} width={85}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>생년월일</Text>
                </TextWrapper>
                <TextWrapper marginLeft={4} width={94}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>핸드폰번호</Text>
                </TextWrapper>
                <TextWrapper marginLeft={4} width={191}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>회원권</Text>
                </TextWrapper>
                <TextWrapper marginLeft={4} width={75}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>시작일</Text>
                  <SortingButton img={startButtonImg} onClick={() => onClickStartSort()} />
                </TextWrapper>
                <TextWrapper marginLeft={4} width={75}>
                  <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>종료일</Text>
                  <SortingButton img={endButtonImg} onClick={() => onClickEndSort()} />
                </TextWrapper>
              </TotalMemberColumnWrppaer>
              <TotalMemberListWrapper>
                {
                  sortedMembers.map((data, index) => (
                    <TotalMemberInfo key={'TotalSendMember-' + index} onClick={() => onClickMemberInfo(data)}>
                      <MemberCheckBox isClicked={data.isClicked}>
                        { data.isClicked && <CheckImage src={CheckImagePath}/> }
                      </MemberCheckBox>
                      <TextWrapper marginLeft={12} width={60}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.name}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={50}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.gender === 'male' ? '남성' : '여성'}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={85}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.birth.slice(2,4) + '.' + data.birth.slice(4,6) + '.' + data.birth.slice(6,8)}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={94}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.phone === '' ? '없음' : data.phone}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={191}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.membershipName}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={75}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.startDate.split('T')[0].replaceAll('-', '.')}</Text>
                      </TextWrapper>
                      <TextWrapper marginLeft={4} width={75}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#595959' hover='pointer'>{data.endDate.split('T')[0].replaceAll('-', '.')}</Text>
                      </TextWrapper>
                      {
                        (moment(data.endDate).diff(moment(), 'day') >= 0 && moment(data.endDate).diff(moment(), 'day') <= 5) &&
                        <TextWrapper marginLeft={4} width={45}>
                          <Dday>{moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day') === 0 ? 'D-day' : 'D-' + moment(moment(data.endDate).format('YYYY-MM-DD')).diff(moment(moment().format('YYYY-MM-DD')), 'day')}</Dday>
                        </TextWrapper>
                      }
                    </TotalMemberInfo>
                  ))
                }
              </TotalMemberListWrapper>
              <PaginationTotalWrapper>
                <PaginationWrapper>
                  <Text onClick={onClickPrevButton} fontFamily='NotoSansKR-Regular' fontSize={14} color={nowPage === 0 ? '#A7A7A7' : '#232323'} hover='pointer'>이전</Text>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={14} color='#232323' hover='default'>{(nowPage + 1) + '/' + totalPage}</Text>
                  <Text onClick={onClickNextButton} fontFamily='NotoSansKR-Regular' fontSize={14} color={(nowPage + 1) === totalPage ? '#A7A7A7' :'#232323'} hover='pointer'>다음</Text>
                </PaginationWrapper>
              </PaginationTotalWrapper>
            </TotalMemberBox>
            <SelectedMemberBoxWrapper>
              <SelectedTextWrapper>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#777777' hover='default'>현재</Text>
                <Text fontFamily='NotoSansKR-Bold' fontSize={16} color='#FF8B48' hover='default'>{decideSelectedCountText()}</Text>
                <Text fontFamily='NotoSansKR-Regular' fontSize={16} color='#777777' hover='default'>선택했어요</Text>
              </SelectedTextWrapper>
              <DivisionLine/>
              <SelectedMemberBox>
                <SelectedMemberInfoWrapper>
                  {
                    originalSortedMembers.map((data, index) => (
                      data.isClicked &&
                      <SelectedMemberInfo key={'SelectedSortMember-' + index}>
                        <ProfileWrapper isFirst={index === 0} isLast={originalSortedMembers.length - 1 === index}>
                          <UserProfile profile={data.profile} defaultProfile={DefaultProfile} />
                        </ProfileWrapper>
                        <TextWrapper marginLeft={10} width={45}>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.name}</Text>
                        </TextWrapper>
                        <TextWrapper marginLeft={4} width={98}>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='default'>{data.phone === '' ? '없음' : data.phone.slice(0, 3) + '-' + data.phone.slice(3, 7) + '-' + data.phone.slice(7, 11)}</Text>
                        </TextWrapper>
                        <DeleteButtonWrapper onClick={() => onClickDeleteButton(data)}>
                          <DeleteButton>
                            <Stick width={10} height={2} radius={0} backgroundColor='#969696'/>
                            <Stick width={2} height={10} radius={0} backgroundColor='#969696'/>
                          </DeleteButton>
                        </DeleteButtonWrapper>
                      </SelectedMemberInfo>
                    ))
                  }
                </SelectedMemberInfoWrapper>
              </SelectedMemberBox>
            </SelectedMemberBoxWrapper>
          </TotalMemberBoxWrapper>
        </ModalInnerWrapper>
      </Modal>
    </TopLevelWrapper>
  )
}

const startAnimation = keyframes`
	0% { opacity: 0; }
	50% { opacity: 0.5; }
	100% { opacity: 1; }
`;

const TopLevelWrapper = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  z-index: 1000;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.2);
	
	animation-name: ${startAnimation};
	animation-duration: 0.5s;
	animation-timing-function: ease-out;
`;

const Modal = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 1053px;
  height: 662px;

  border-radius: 15px;
  box-shadow: 0px 40px 50px rgba(0, 0, 0, 0.1);
  background-color: #FDFCFB;
`;

const ModalInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 40px);
  height: 626px;
`;

const TopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 10px;
`;

const ExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 62px;
  height: 40px;

  border-radius: 10px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const AdditionButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100px;
  height: 40px;

  border-radius: 10px;
  background-color: #5BD98A;

  :hover {
    cursor: pointer;
  }
`;

const SearchInputWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 20px;

  width: 100%;
`;

const ResetAndSortingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  margin-top: 12px;

  width: 100%;
`;

const SortingButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 16px;
`;

const FilterResetButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 83px;
  height: 20px;

  border: 1px solid #DBDDE4;
  border-radius: 5px;
  background-color: #F5F6F8;

  :hover {
    cursor: pointer;
  }
`;

const TotalMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: flex-start;

  margin-top: 20px;

  width: 1013px;
  height: 473px;
`;

const TotalMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 752px;
  height: 430px;
`;

const TotalMemberColumnWrppaer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 6px;

  width: calc(100% - 12px);
`;

const MemberCheckBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 16px;
  height: 16px;

  border: ${props => props.isClicked ? `1px solid #5BD98A` : `1px solid #969696`};
  border-radius: 5px;
  background-color: ${props => props.isClicked ? `#5BD98A` : `#FFFFFF`};

  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  margin-left: ${props => props.marginLeft}px;

  width: ${props => props.width}px;
  height: 20px;

  overflow: hidden;
`;

const SortingButton = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin-left: 4px;

  width: 20px;
  height: 20px;

  background: url(${props => props.img}) no-repeat center center;

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const TotalMemberListWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 15px;

  width: 100%;
  min-height: 395px;
  max-height: 395px;

  gap: 5px;
`;

const TotalMemberInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 0px 6px;

  width: calc(100% - 12px);
  height: 35px;

  box-shadow: 0px 40px 50px rgba(240, 232, 227, 0.5);
  border-radius: 10px;
  background-color: #FFFFFF;

  :hover {
    cursor: pointer;
  }
`;

const PaginationTotalWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin-top: 16px;

  width: 100%;
`;

const PaginationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 12px;
`;

const SelectedMemberBoxWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 241px;
  height: 378px;
`;

const SelectedTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  gap: 4px;
`;

const SelectedMemberBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 241px;
  height: 333px;

  overflow-x: hidden;
  overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 9px;
	}

	&::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 20px #DFDFDF;
		border: 3px solid transparent;
		height: 25px;
		// background-color: #FF8B48;
		border-radius: 200px;
	}

	&::-webkit-scrollbar-track {
    box-shadow: inset 0 0 10px 10px transparent;
		border: 4px solid transparent;
		background-color: transparent;
	}

	&::-webkit-scrollbar-track-piece {
		background-color: transparent;
	}
`;

const SelectedMemberInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 234px;

  border-radius: 10px;
  background-color: #FFFFFF;
`;

const SelectedMemberInfo = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: 37px;
`;

const ProfileWrapper = styled.div`
  width: 37px;
  height: 37px;

  // border-radius: 4px;
  border-top-left-radius: ${props => props.isFirst ? `4px` : `0px`};
  border-bottom-left-radius: ${props => props.isLast ? `4px` : `0px`};

  overflow: hidden;
`;

const DivisionLine = styled.div`
  display: flex;
  flex-direction: column;

  margin: 10px 0px;

  width: 194px;
  height: 1px;

  border-radius: 2px;
  background-color: #D9D9D9;
`;

const Dday = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 45px;
  height: 20px;

  border-radius: 30px;
  background-color: #FF8B48;

  font-family: NotoSansKR-Regular;
  font-size: 11px;

  color: #FFFFFF;

  margin: 0 0 0 0;
`;

const DeleteButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-left: 10px;

  width: 23px;
  height: 23px;

  :hover {
    cursor: pointer;
  }
`;

const DeleteButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transform: rotate(45deg);
`;

const Stick = styled.div`
  position: absolute;
  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: ${props => props.backgroundColor};
`;

const CheckImage = styled.img`
  width: 10px;
  height: 8px;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;

  :hover {
    cursor: ${props => props.hover};
  }
`;