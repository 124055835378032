import React from 'react';
import styled from 'styled-components';

import TermsIcon from '../../image/TermsIcon.png';
import WhitePencelIcon from '../../image/WhitePencel.svg';

export default function TermContract(props) {
  const { isClicked, terms } = props;

  return (
    <TopLevelWrapper>
      <TitleWrapper>
        <TitleBox backgroundColor='#F17DB4'>
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='default'>전자 계약</Text>
        </TitleBox>
        <ModifyButton isClicked={isClicked}>
          {!isClicked && <PencelImage src={WhitePencelIcon} />}
          <Text fontFamily='NotoSansKR-Bold' fontSize={14} color='#FFFFFF' hover='pointer'>{isClicked ? `저장하기` : `수정하기`}</Text>
        </ModifyButton>
      </TitleWrapper>
      <InfoView>
        <InnerTextWrapper>
          <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#232323' hover='default'>만들어진 계약서</Text>
        </InnerTextWrapper>
        <TermsViewWrapper>
          <TermBox>
            <ImageWrapper>
              <Image src={TermsIcon}/>
            </ImageWrapper>
            <TermInfoWrapper>
              <Text fontFamily='NotoSansKR-Medium' fontSize={14} color='#777777' hover='pointer'>회원계약서(제목)</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='pointer'>게시일 : 2024.02.02</Text>
              <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#777777' hover='pointer'>수정일 : 2024.02.04</Text>
            </TermInfoWrapper>
          </TermBox>
        </TermsViewWrapper>
      </InfoView>
    </TopLevelWrapper>
  )
}

const TopLevelWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 30px;

  width: 100%;
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  width: 100%;
`;

const TitleBox = styled.p`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 2px 20px;
  margin: 0 0 0 0;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  background-color: ${props => props.backgroundColor};
`;

const ModifyButton = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: 82px;
  height: 24px;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  
  background-color: ${props => props.isClicked ? `#6DD49E` : `#CBCBCB`};

  transition: all 0.3s ease-in-out;

  :hover {
    cursor: pointer;
  }
`;

const InfoView = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  padding: 10px 20px;

  width: calc(100% - 40px);

  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  background-color: #FFFFFF;
`;

const InnerTextWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: center;

  padding: 4px 0px 14px 0px;

  width: 1115px;

  border-bottom: 2px solid #F8F8F8;
`;

const TermsViewWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 10px 15px;
  margin-top: 10px;

  width: calc(100% - 20px);
  height: 150px;

  border-radius: 10px;
  background-color: #F8F8F8;
`;

const PencelImage = styled.img`
  width: 20px;
  height: 20px;

  :hover {
    cursor: pointer;
  }
`;

const TermBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 150px;
  height: 150px;

  border-radius: 15px;

  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  align-items: center;

  width: 100%;
  height: 94px;

  background-color: #FFFFFF;
`;

const Image = styled.img`
  width: 70px;
  height: 84px;

  object-fit: contain;
`;

const TermInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  padding: 2px 10px 5px 10px;

  width: calc(100% - 20px);
  height: 49px;

  background-color: #FCFCFC;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  margin: 0 0 0 0;

  color: ${props => props.color};

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;