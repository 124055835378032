import React from "react";
import styled from "styled-components";
import lodash from 'lodash';

import UserProfile from "../../Component/Normal/UserProfile";

import { makeUpWorkout } from "../Transaction/Transaction";

import DefaultLogoPath from '../../image/DefaultLogo.png';
import WhiteExercisePlusPath from '../../image/WhiteExercisePlus.png';
import OrangeExercisePlusPath from '../../image/OrangeExercisePlus.png';
import WhitePenPath from '../../image/WhitePen.png';
import RedLockerPath from '../../image/RedLocker.png';
import GrayLockerPath from '../../image/GrayLocker.png';
import FetchModule from "../../Model/Share/Network/FetchModule";

export default function WodBox(props) {
  const {
    storeLogoSize,
    sizeDivision,
    storeTextBoxWidth,
    today,
    data,
    weekIndex,
    dayIndex,
    clickWeekIndex,
    clickDayIndex,
    clickWodIndex,
    onClickWod,

    dates,
    refreshTotalData,

    // videoWidth,
    // videoHeight,

    copyWodInfo,
    setCopyWodInfo,
    lastCopyWodInfo,
    setLastCopyWodInfo,
    isViewCompleteCopy,
    // setIsViewCompleteCopy,

    setOpenPopUp,
    setLockPopUp
  } = props;

  // 한자리 숫자 앞에 0 붙이기 //
  function numberPad(n, width) {
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
  }

  // 현재 클릭되어있는지 여부 결정 //
  function decideOnClickedWod() {
    return (weekIndex === clickWeekIndex && dayIndex === clickDayIndex);
  }

  // 와드가 저장되어있는지 여부 결정 //
  function decideAlreadySaveWods() {
    return data.wods.length !== 0;
  }

  // 날짜 표출여부 결정 //
  function decideDateView() {
    if (today.format('YYYY.M.D') === data.date.format('YYYY.M.D')) {
      if (sizeDivision !== 'Max') return false;
      else return true;
    }
    else return true;
  }

  // EmptyWodBox 표출 여부 결정 //
  function decideEmptyWodBoxView() {
    return (sizeDivision === 'Max' && data.wods.length === 0);
  }

  // Profile Size 결정 //
  function decideStoreLogoSize() {
    if (sizeDivision === 'Max') return 31;
    else return storeLogoSize;
  }

  // StoreInfo 표출 여부 결정 //
  function decideStoreTextInfoView() {
    return sizeDivision !== 'Min';
  }

  // StoreInfo Text Wrapper Width 결정 //
  function decideStoreTextWrapperWidth() {
    if (sizeDivision === 'Max') return storeTextBoxWidth + 'px';
    else return 'auto';
  }

  // StoreInfo Text Wrapper Height 결정 //
  function decideStoreTextWrapperHeight() {
    if (sizeDivision === 'Max') return '15px';
    else return '30px';
  }

  // 운동 잠금에 의한 전체컴포넌트 Opacity 사용 여부 결정 //
  function decideLockedWodBoxOpaicy(data) {
    return sizeDivision !== 'Min' && data.isLock;
  }

  // 운동 잠금에 의한 업체 로고 잠금아이콘 표출 여부 //
  function decideLockedWodBoxLogoView(data) {
    return sizeDivision !== 'Max' && data.isLock;
  }

  // 운동 잠금에 의한 업체 로고 Opacity 사용 여부 결정 //
  function decideLockedWodBoxLogoOpaicy(data) {
    return sizeDivision === 'Min' && data.isLock;
  }

  // 와드 저장 유무에 따른 와드 추가버튼 테두리 결정 //
  function decideWodAddButtonBorder() {
    if (decideOnClickedWod() && sizeDivision === 'Min') return '1px solid #FF5D00'
    else if (data.wods.length !== 0) return '1px dashed #FF8B48';
    else return '1px solid #FF8B48';
  }

  // 와드 저장 유무 및 클력 여부에 따른 와드 추가버튼 배경색 결정 //
  function decideWodAddButtonBackgroundColor() {
    if (decideOnClickedWod() && sizeDivision === 'Min') return '#FF5D00'
    else if (data.wods.length !== 0) return '#FFFFFF';
    else return '#FF8B48';
  }

  // Section Title 설정
  function decideSectionTitle(data) {
    if (data.tag === 'ForTime') return data.round + ' Round For Time';
    else if (data.tag === 'AMRAP') {
      let min = parseInt(data.exerciseTime / 60);
      let sec = parseInt(data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec AMRAP';
      else if (min > 0) return min + 'min AMRAP';
      else if (sec > 0) return sec + 'sec AMRAP';
      else return 'AMRAP';
    }
    else if (data.tag === 'EMOM') {
      let min = parseInt(data.round * data.exerciseTime / 60);
      let sec = parseInt(data.round * data.exerciseTime % 60);

      if (min > 0 && sec > 0) return min + 'min ' + sec + 'sec EMOM';
      else if (min > 0) return min + ' min EMOM';
      else if (sec > 0) return sec + ' sec EMOM';
      else return 'EMOM';
    }
    else if (data.tag === 'TABATA') {
      let onMin = parseInt(data.exerciseTime / 60);
      let onSec = parseInt(data.exerciseTime % 60);
      let offMin = parseInt(data.restTime / 60);
      let offSec = parseInt(data.restTime % 60);

      return 'On: ' + onMin + ':' + numberPad(onSec, 2) + ' / Off:' + offMin + ':' + numberPad(offSec, 2) + ' ' + data.round + 'R TABATA';
    }
    else if (data.tag === 'SINGLE') {
      if (data.mainExerciseList.length <= 0) return 'SINGLE';
      else if (data.mainExerciseList.length === 1) return data.mainExerciseList[0].movementName;
      else return 'Multiple Single';
    }
    else if (data.tag === 'Custom') {
      if (data.customTitle !== '') return data.customTitle;
      else if (data.scoreKeyword === '점수 없음'
        || data.scoreKeyword === '피크와트'
        || data.scoreKeyword === '최고 속도') return 'Custom : ' + data.scoreKeyword;
      else return 'Custom : ' + data.scoreKeyword + ' ' + data.scorePriority;
    }
  }

  // 와드 복사 및 클릭여부로 테두리 결정 //
  function decideWodBoxBorder(weekIndex, dayIndex, wodIndex) {
    if (copyWodInfo === undefined) {
      if (weekIndex === clickWeekIndex && dayIndex === clickDayIndex && wodIndex === clickWodIndex) return '2px solid #FF8B48';
      else return '2px solid #FFFFFF';
    }
    else {
      if (dates[weekIndex][dayIndex].wods[wodIndex].classDate == copyWodInfo.classDate
        && weekIndex === copyWodInfo.weekIndex
        && dayIndex === copyWodInfo.dayIndex
        && wodIndex === copyWodInfo.wodIndex) return '2px solid #6DD49E';
      else if (weekIndex === clickWeekIndex
        && dayIndex === clickDayIndex
        && wodIndex === clickWodIndex) return '2px solid #FF8B48';
      else return '2px solid #FFFFFF';
    }
  }

  // // 와드 복사 및 클릭여부로 배경 결정 //
  // function decideWodBoxBackgroundColor(weekIndex, dayIndex, wodIndex) {
  //   if (copyWodInfo === undefined) {
  //     if (weekIndex === clickWeekIndex && dayIndex === clickDayIndex && wodIndex === clickWodIndex) return '#FF8B48';
  //     else return '#FFFFFF';
  //   }
  //   else {
  //     if (dates[weekIndex][dayIndex].wods[wodIndex].classDate === copyWodInfo.classDate
  //       && weekIndex === copyWodInfo.weekIndex
  //       && dayIndex === copyWodInfo.dayIndex
  //       && wodIndex === copyWodInfo.wodIndex) return 'rgba(109, 212, 158, 0.05)';
  //     else if (weekIndex === clickWeekIndex
  //       && dayIndex === clickDayIndex
  //       && wodIndex === clickWodIndex) return '2px solid #FF8B48';
  //     else return '2px solid #FFFFFF';
  //   }
  // }

  // 와드 복사시 띄워줄 Header Component Opacity 결정 //
  function decideCopyHeaderComponentOpacity(classDate, weekIndex, dayIndex, wodIndex) {
    if (copyWodInfo === undefined) return 0;
    else if (isViewCompleteCopy
      && copyWodInfo.classDate === classDate
      && copyWodInfo.weekIndex === weekIndex
      && copyWodInfo.dayIndex === dayIndex
      && copyWodInfo.wodIndex === wodIndex) return 1;
    else return 0;
  }

  // 복사하기 와드가 있는데 다른곳 클릭한 경우 붙여넣을건지 물어보기 //
  function decideOnCopyWod(classDate, weekIndex, dayIndex, wodIndex) {
    if (copyWodInfo === undefined) return 0;
    else if (copyWodInfo.classDate !== classDate
      && clickWeekIndex === weekIndex
      && clickDayIndex === dayIndex
      && clickWodIndex === wodIndex) {
        if (lastCopyWodInfo === undefined
          || lastCopyWodInfo.weekIndex !== weekIndex
          || lastCopyWodInfo.dayIndex !== dayIndex
          || lastCopyWodInfo.wodIndex !== wodIndex
        ) return 1;
        else return 0;
    }
    else {
      return 0;
    }
  }

  // 붙여넣기 실행 //
  function onClickPaste() {
    if (copyWodInfo === undefined) return;
    else if (copyWodInfo.classDate === data.wods[clickWodIndex].classDate
      && copyWodInfo.weekIndex === clickWeekIndex
      && copyWodInfo.dayIndex === clickDayIndex
      && copyWodInfo.wodIndex === clickWodIndex) {
      setCopyWodInfo(undefined);
    }
    else {
      let getPastedWod = dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex];
      let copyWod = lodash.cloneDeep(copyWodInfo.copyWod);

      copyWod.workoutId = getPastedWod.workoutId;

      // Section ID 없애기 //
      for (let i = 0; i < copyWod.sectionList.length; i++) {
        copyWod.sectionList[i].sectionId = undefined;

        if (copyWod.sectionList[i].scaleSections !== undefined) {
          // ScaleSection ID Setting //
          for (let j = 0; j > copyWod.sectionList[i].scaleSections.length; j++) {
            copyWod.sectionList[i].scaleSections[j].sectionId = undefined;
            copyWod.sectionList[i].scaleSections[j].derivationSectionId = undefined;

            for (let h = 0; h < copyWod.sectionList[i].scaleSections[j].mainExerciseList.length; h++) {
              copyWod.sectionList[i].scaleSections[j].mainExerciseList[h].sectionId = undefined;
              copyWod.sectionList[i].scaleSections[j].mainExerciseList[h].exerciseId = undefined;
            }
          }
        }

        if (copyWod.sectionList[i].buyinExerciseList !== undefined) copyWod.sectionList[i].buyinExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].buyinScaleOptionExerciseList !== undefined) copyWod.sectionList[i].buyinScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].mainExerciseList !== undefined) copyWod.sectionList[i].mainExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].mainScaleOptionExerciseList !== undefined) copyWod.sectionList[i].mainScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].emomExerciseList !== undefined) copyWod.sectionList[i].emomExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].emomScaleOptionExerciseList !== undefined) copyWod.sectionList[i].emomScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].buyoutExerciseList !== undefined) copyWod.sectionList[i].buyoutExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
        if (copyWod.sectionList[i].buyoutScaleOptionExerciseList !== undefined) copyWod.sectionList[i].buyoutScaleOptionExerciseList.map((data) => (data.exerciseId = undefined, data.sectionId = undefined));
      }

      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex] = copyWod;
      dates[clickWeekIndex][clickDayIndex].wods[clickWodIndex].isCompleteSaved = false;

      refreshTotalData();

      setLastCopyWodInfo({
        weekIndex: clickWeekIndex,
        dayIndex: clickDayIndex,
        wodIndex: clickWodIndex
      });
    }
  }

  // 잠금버튼 클릭시 //
  function onClickLockButton(weekIndex, dayIndex, wodIndex) {
    if (dates[weekIndex][dayIndex].wods[wodIndex].workoutId === undefined) {
      dates[weekIndex][dayIndex].wods[wodIndex].isLock = !dates[weekIndex][dayIndex].wods[wodIndex].isLock;
      dates[weekIndex][dayIndex].wods[wodIndex].isCompleteSaved = false;
      refreshTotalData();

      if (dates[weekIndex][dayIndex].wods[wodIndex].isLock) {
        setLockPopUp({
          width: 189,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setLockPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
      else {
        setOpenPopUp({
          width: 214,
          marginTop: -60,
          opacity: 1
        })

        setTimeout(() => (
          setOpenPopUp({
            width: 0,
            marginTop: 35,
            opacity: 0
          })
        ), 1500)
      }
    }
    else {
      let requestData = {
        workoutId: dates[weekIndex][dayIndex].wods[wodIndex].workoutId
      }

      const fetchModule = new FetchModule();
      (
        async () => {
          const responseData = await fetchModule.postDataOfExerciseVersion2('workout/replace_lock', 'POST', requestData);
          // console.log(responseData.data)
          if (responseData.status === 200) {
            dates[weekIndex][dayIndex].wods[wodIndex].isLock = !dates[weekIndex][dayIndex].wods[wodIndex].isLock;
            refreshTotalData();

            if (dates[weekIndex][dayIndex].wods[wodIndex].isLock) {
              setLockPopUp({
                width: 189,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setLockPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
            else {
              setOpenPopUp({
                width: 214,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setOpenPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
          }
          else if (responseData.status === 201) {
            // dates[weekIndex][dayIndex].wods[wodIndex].isLock = !dates[weekIndex][dayIndex].wods[wodIndex].isLock;
            // dates[weekIndex][dayIndex].wods[wodIndex].isCompleteSaved = false;

            let saveStatus = dates[weekIndex][dayIndex].wods[wodIndex].isCompleteSaved;
              
            let newWod = [];
            
            newWod.push(JSON.parse(JSON.stringify(responseData.data)));

            let finalWod = makeUpWorkout(newWod)[0];

            dates[weekIndex][dayIndex].wods[wodIndex] = finalWod;
            dates[weekIndex][dayIndex].wods[wodIndex].isCompleteSaved = saveStatus;
            refreshTotalData();

            if (dates[weekIndex][dayIndex].wods[wodIndex].isLock) {
              setLockPopUp({
                width: 189,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setLockPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
            else {
              setOpenPopUp({
                width: 214,
                marginTop: -60,
                opacity: 1
              })

              setTimeout(() => (
                setOpenPopUp({
                  width: 0,
                  marginTop: 35,
                  opacity: 0
                })
              ), 1500)
            }
          }
        }
      )();
    }
  }

  // 복사 붙여넣기 진행시 붙여넣을 와드 표시 //
  function onViewWillCopyWod() {
    if (copyWodInfo === undefined) return;

    return (
      <WodBoxWrapper
        style={{ opacity: 0.4, zIndex: 3 }}
        border='2px solid #FFFFFF'
        isLock={decideLockedWodBoxOpaicy(copyWodInfo.copyWod)}
        tabIndex={0}>
        <WodStoreWrapper>
          <StoreInfoWrapper isMid={sizeDivision === 'Mid'}>
            <StoreLogoWrapper size={decideStoreLogoSize()} isLock={decideLockedWodBoxLogoOpaicy(copyWodInfo.copyWod)}>
              {
                decideLockedWodBoxLogoView(copyWodInfo.copyWod) &&
                <LockerWrapper style={{ position: 'absolute' }}>
                  <LockerImage src={RedLockerPath} />
                </LockerWrapper>
              }
              <UserProfile profile={copyWodInfo.copyWod.storeLogo} defaultProfile={DefaultLogoPath} />
            </StoreLogoWrapper>
            {
              decideStoreTextInfoView() &&
              <StoreTextInfoWrapper>
                <TextWrapper width={decideStoreTextWrapperWidth()} height='15px'>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#000000' hover='pointer'>{copyWodInfo.copyWod.lessonName}</Text>
                </TextWrapper>
                <TextWrapper width={decideStoreTextWrapperWidth()} height={decideStoreTextWrapperHeight()}>
                  <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#000000' hover='pointer'>{copyWodInfo.copyWod.storeName}</Text>
                </TextWrapper>
              </StoreTextInfoWrapper>
            }
          </StoreInfoWrapper>
          {
            sizeDivision === 'Max' &&
            <LockerWrapper>
              <LockerImage src={copyWodInfo.copyWod.isLock ? RedLockerPath : GrayLockerPath} />
            </LockerWrapper>
          }
        </WodStoreWrapper>
        {
          (copyWodInfo.copyWod.wodVersion === 2 && sizeDivision !== 'Min') &&
          <WriteWodBox>
            {
              copyWodInfo.copyWod.sectionList.map((sectionData, sectionIndex) => (
                <WodPrevViewWrapper key={'Prev-' + sectionIndex}>
                  <WodPrevViewSectionTitleWrapper>
                    <SectionBox>
                      <SmallText style={{ marginTop: '2px' }} fontFamily='Poppins-SemiBold' fontSize={10} color='#FFFFFF' hover='pointer'>{String.fromCharCode(sectionIndex + 65)}</SmallText>
                    </SectionBox>
                    <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#000000' hover='pointer'>{decideSectionTitle(sectionData)}</Text>
                  </WodPrevViewSectionTitleWrapper>
                  <PreText fontFamily='NotoSansKR-Regular' fontSize={10} color='#000000' hover='pointer'>{sectionData.mainProgram}</PreText>
                </WodPrevViewWrapper>
              ))
            }
          </WriteWodBox>
        }
      </WodBoxWrapper>
    )
  }

  return (
    <WodContainer>
      <TotalWrapper>
        <DateBox isToday={today.format('YYYY.M.D') === data.date.format('YYYY.M.D')} isMinimum={sizeDivision !== 'Max'}>
          {decideDateView() && <Text fontFamily='NotoSansKR-Regular' fontSize={12} color={today.format('YYYY.M.D') === data.date.format('YYYY.M.D') ? '#FFFFFF' : '#000000'}>{sizeDivision === 'Min' ? data.date.format('M.D') : data.date.format('YYYY.M.D')}</Text>}
          {today.format('YYYY.M.D') === data.date.format('YYYY.M.D') && <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF'>TODAY</Text>}
        </DateBox>
        {
          decideEmptyWodBoxView() &&
          <EmptyWodBox onClick={() => onClickWod(data.date, undefined, weekIndex, dayIndex, undefined)}>

          </EmptyWodBox>
        }
        {
          data.wods.map((wodData, wodIndex) => (
            <WodBoxWrapper
              id={'Wod-' + weekIndex.toString() + '-' + dayIndex.toString() + '-' + wodIndex.toString()}
              key={'Wod-' + weekIndex + '-' + dayIndex + '-' + wodIndex}
              // isClicked={weekIndex === clickWeekIndex && dayIndex === clickDayIndex && wodIndex === clickWodIndex}
              border={() => decideWodBoxBorder(weekIndex, dayIndex, wodIndex)}
              isLock={decideLockedWodBoxOpaicy(wodData)}
              // tabIndex={0}
              // onKeyDown={onKeyDown}
              onClick={() => onClickWod(data.date, wodData, weekIndex, dayIndex, wodIndex)}>
              <CopyWodHeader
                isViewCompleteCopy={isViewCompleteCopy}
                opacity={() => decideCopyHeaderComponentOpacity(wodData.classDate, weekIndex, dayIndex, wodIndex)}>
                <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF' hover='pointer'>복사 완료</Text>
              </CopyWodHeader>
              {
                sizeDivision !== 'Min' &&
                <PasteWodTotalWrapper isView={decideOnCopyWod(data.date.format('YYYY-MM-DDT00:00:00'), weekIndex, dayIndex, wodIndex)}>
                <PasteWodHeader opacity={() => decideOnCopyWod(data.date.format('YYYY-MM-DDT00:00:00'), weekIndex, dayIndex, wodIndex)}>
                  <PasteButtonWrapper>
                    <PasteButton onClick={onClickPaste}>
                      <Text fontFamily='NotoSansKR-Bold' fontSize={12} color='#FFFFFF' hover='pointer'>붙여 넣기</Text>
                    </PasteButton>
                    <PasteExitButton onClick={() => setCopyWodInfo(undefined)}>
                      <ExitButton>
                        <ExitWidth width={14} height={2} radius={4} />
                        <ExitWidth width={2} height={14} radius={4} />
                      </ExitButton>
                    </PasteExitButton>
                  </PasteButtonWrapper>
                  {onViewWillCopyWod()}
                </PasteWodHeader>
              </PasteWodTotalWrapper>
              }
              <WodStoreWrapper>
                <StoreInfoWrapper isMid={sizeDivision === 'Mid'}>
                  <StoreLogoWrapper size={decideStoreLogoSize()} isLock={decideLockedWodBoxLogoOpaicy(wodData)}>
                    {
                      decideLockedWodBoxLogoView(wodData) &&
                      <LockerWrapper style={{ position: 'absolute' }}>
                        <LockerImage src={wodData.isLock ? RedLockerPath : GrayLockerPath} />
                      </LockerWrapper>
                    }
                    <UserProfile profile={wodData.storeLogo} defaultProfile={DefaultLogoPath} />
                  </StoreLogoWrapper>
                  {
                    decideStoreTextInfoView() &&
                    <StoreTextInfoWrapper>
                      <TextWrapper width={decideStoreTextWrapperWidth()} height='15px'>
                        <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#000000' hover='pointer'>{wodData.lessonName}</Text>
                      </TextWrapper>
                      <TextWrapper width={decideStoreTextWrapperWidth()} height={decideStoreTextWrapperHeight()}>
                        <Text fontFamily='NotoSansKR-Regular' fontSize={10} color='#000000' hover='pointer'>{wodData.storeName}</Text>
                      </TextWrapper>
                    </StoreTextInfoWrapper>
                  }
                </StoreInfoWrapper>
                {
                  sizeDivision === 'Max' &&
                  <LockerWrapper onClick={() => onClickLockButton(weekIndex, dayIndex, wodIndex)}>
                    <LockerImage src={wodData.isLock ? RedLockerPath : GrayLockerPath} />
                  </LockerWrapper>
                }
              </WodStoreWrapper>
              {
                (wodData.wodVersion === 2 && sizeDivision !== 'Min') &&
                // <WriteWodBox isClicked={weekIndex === clickWeekIndex && dayIndex === clickDayIndex && wodIndex === clickWodIndex}>
                <WriteWodBox>
                  {
                    wodData.sectionList.map((sectionData, sectionIndex) => (
                      <WodPrevViewWrapper key={'Prev-' + sectionIndex}>
                        <WodPrevViewSectionTitleWrapper>
                          <SectionBox>
                            {/* <Text style={{ marginTop: '2px', zoom: '0.8' }} fontFamily='Poppins-SemiBold' fontSize={10} color='#FFFFFF' hover='pointer'>{String.fromCharCode(sectionIndex + 65)}</Text> */}
                            <SmallText style={{ marginTop: '2px' }} fontFamily='Poppins-SemiBold' fontSize={10} color='#FFFFFF' hover='pointer'>{String.fromCharCode(sectionIndex + 65)}</SmallText>
                          </SectionBox>
                          <Text fontFamily='NotoSansKR-Medium' fontSize={10} color='#000000' hover='pointer'>{decideSectionTitle(sectionData)}</Text>
                        </WodPrevViewSectionTitleWrapper>
                        {/* {
                          (sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') &&
                          <VideoViewWrapper
                            width={videoWidth}
                            height={videoHeight}>
                            <iframe
                              width="100%"
                              height="100%"
                              src={(sectionData.youtubeLink !== undefined && sectionData.youtubeLink !== '') ? `https://www.youtube.com/embed/` + sectionData.youtubeLink.replace('https://www.youtube.com/watch?v=', '').replace('https://youtube.com/shorts/', '').replace('https://youtu.be/', '') : ''}
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen
                              title="Embedded youtube" />
                          </VideoViewWrapper>
                        } */}
                        { sectionData.tag !== 'SINGLE' && <PreText fontFamily='NotoSansKR-Regular' fontSize={10} color='#000000' hover='pointer'>{sectionData.mainProgram}</PreText> }
                      </WodPrevViewWrapper>
                    ))
                  }
                </WriteWodBox>
              }
              {
                !wodData.isCompleteSaved &&
                <SavingBox>
                  <Text fontFamily='NotoSansKR-Bold' fontSize={10} color='#FFFFFF' hover='default'>작성중</Text>
                </SavingBox>
              }
            </WodBoxWrapper>
          ))
        }
        {
          // (!decideOnClickedWod() || sizeDivision === 'Min') &&
          <WodAddButton
            isMinimum={sizeDivision === 'Min'}
            isClicked={decideOnClickedWod()}
            border={decideWodAddButtonBorder()}
            backgroundColor={decideWodAddButtonBackgroundColor()}
            onClick={() => onClickWod(data.date, undefined, weekIndex, dayIndex, undefined)}>
            <PenImage src={WhitePenPath} isMinimum={sizeDivision === 'Min'} isClicked={(decideOnClickedWod() && sizeDivision === 'Min')} />
            <PlusImage src={decideAlreadySaveWods() ? OrangeExercisePlusPath : WhiteExercisePlusPath} isMinimum={sizeDivision === 'Min'} isClicked={(decideOnClickedWod() && sizeDivision === 'Min')} />
          </WodAddButton>
        }
      </TotalWrapper>
    </WodContainer>
  )
}

const WodContainer = styled.section`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: center;

  padding: 5px 0;

  // max-width: 164px;
  width: 100%;

  overflow: hidden;

  border-radius: 5px;
  background-color: #FFFFFF;
`;

const TotalWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 96%;

  gap: 10px;
`;

const DateBox = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: ${props => props.isMinimum ? `center` : `space-between`};
  align-items: center;

  padding: 0px 4px 0px 4px;

  width: calc(100% - 8px);
  height: 22px;

  border-radius: 3px;
  background-color: ${props => props.isToday ? `#6DD49E` : `#FFFFFF`};
`;

const WodBoxWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: calc(100% - 4px);

  border: ${props => props.border};
  border-radius: 4px;

  opacity: ${props => props.isLock ? '0.5' : '1'};

  :hover {
    cursor: pointer;
  }

  outline: none;

  transition: all 0.3s ease-in-out;
`;

const CopyWodHeader = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: ${props => props.isViewCompleteCopy ? `6px` : `0px`};

  width: 87.38%;
  // height: 25px;
  height: ${props => props.isViewCompleteCopy ? `25px` : `0px`};

  opacity: ${props => props.opacity};

  border-radius: 5px;

  overflow: hidden;

  z-index: 1;

  background-color: #6DD49E;
  box-shadow: 0px 10px 30px 0px #3D975E;

  transition: all 0.3s ease-in-out;
`;

const PasteWodTotalWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  width: 100%;
  height: ${props => props.isView ? '100%' : '0px'};

  // opacity: ${props => props.opacity};

  border-radius: 5px;

  overflow: hidden;

  z-index: 3;
`;

const PasteWodHeader = styled.div`
  // position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  margin-top: ${props => props.isViewCompleteCopy ? `6px` : `0px`};

  width: 100%;
  height: 100%;

  opacity: ${props => props.opacity};

  border-radius: 5px;

  overflow: hidden;

  z-index: 3;

  // border: 2px solid #FF8B48;
  background-color: #FFFFFF;

  transition: all 0.3s ease-in-out;
`;

const PasteButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 100%;

  z-index: 5;
`;

const PasteButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 8px;

  width: 87.38%;
  height: 25px;

  border-radius: 5px;
  
  background-color: #FF8B48;
  box-shadow: 0px 10px 30px 0px #FF8B48;
`;

const PasteExitButton = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-top: 12px;
  
  width: 87.38%;
  height: 25px;

  border: 1px solid #FF8B48;
  border-radius: 5px;

  background-color: #FFFFFF;
  box-shadow: 0px 10px 30px 0px #FF8B48;
`;

const ExitButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  transform:rotate(45deg);
`;

const ExitWidth = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.width}px;
  height: ${props => props.height}px;

  border-radius: ${props => props.radius}px;
  background-color: #FF8B48;
`;

const WodStoreWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;

  padding: 0px 2px;

  width: calc(100% - 4px);

  overflow: hidden;
`;

const StoreInfoWrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.isMid ? `column` : `row`};

  justify-content: center;
  align-items: flex-start;

  gap: 3px;
  
  transition: all 0.3s ease-in-out;
`;

const StoreLogoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  width: ${props => props.size}px;
  height: ${props => props.size}px;

  overflow: hidden;

  opacity: ${props => props.isLock ? '0.5' : '1'}; 
  border-radius: 4px;

  transition: all 0.3s ease-in-out;
`;

const StoreTextInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: flex-start;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width};
  height: ${props => props.height};

  overflow: hidden;
`;

const LockerWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  width: 15px;
  min-width: 15px;
  height: 21px;
  min-height: 21px;

  :hover {
    cursor: pointer;
  }
`;

const LockerImage = styled.img`
  width: 100%;
  height: 100%;

  transition: all 0.3s ease-in-out;
`;

const WriteWodBox = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: flex-start;
  align-items: flex-start;

  margin-top: 10px;

  width: calc(100% - 4px);
  // min-height: 76px;
  min-height: 200px;

  gap: 20px;

  border: ${props => props.isClicked ? `2px solid #FF8B48` : `2px solid #FFFFFF`};
  border-radius: 4px;
  background-color: ${props => props.isClicked ? `rgba(255, 139, 72, 0.05)` : `#FFFFFF`};

  transition: all 0.3s ease-in-out;
`;

const WodPrevViewWrapper = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  gap: 10px;
`;

const WodPrevViewSectionTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: flex-start;
  align-items: flex-start;

  gap: 4px;

  width: 100%;
`;

const SectionBox = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  margin-top: 2px;

  width: 10px;
  min-width: 10px;
  height: 10px;
  min-height: 10px;

  border-radius: 4px;
  background-color: #6DD49E;
`;

const EmptyWodBox = styled.div`
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 22px;

  border-radius: 5px;
  background-color: rgba(255, 139, 72, 0.05);

  :hover {
    cursor: pointer;
  }
`;

const WodAddButton = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  justify-content: center;
  align-items: center;

  width: calc(100% - 2px);
  height: ${props => props.isMinimum ? `18px` : `24px`};

  border: ${props => props.border};
  border-radius: ${props => props.isMinimum ? `5px` : `30px`};
  background-color: ${props => props.backgroundColor};
  
  :hover {
    cursor: pointer;
  }

  transition: all 0.3s ease-in-out;
`;

const PenImage = styled.img`
  position: absolute;

  width: ${props => props.isMinimum ? `10px` : `14px`};
  height: ${props => props.isMinimum ? `10px` : `14px`};

  opacity: ${props => props.isClicked ? `1` : `0`};

  transition: all 0.3s ease-in-out;
`;

const PlusImage = styled.img`
  position: absolute;

  width: ${props => props.isMinimum ? `12px` : `20px`};
  height: ${props => props.isMinimum ? `12px` : `20px`};

  opacity: ${props => props.isClicked ? `0` : `1`};

  transition: all 0.3s ease-in-out;
`;

const PreText = styled.pre`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  width: 100%;

  white-space: pre-wrap;
  word-wrap: break-word;

  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;


const VideoViewWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: flex-start;

  width: ${props => props.width}px;
  height: ${props => props.height}px;
  
  border-radius: 5px;
  overflow: hidden;

  transition: all 0.3s ease-in-out;
`;

const Text = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const SmallText = styled.p`
  font-family: ${props => props.fontFamily};
  font-size: ${props => props.fontSize}px;

  color: ${props => props.color};

  margin: 0 0 0 0;
  zoom: 0.8;
  :hover {
    cursor: ${props => props.hover};
  }

  transition: all 0.3s ease-in-out;
`;

const SavingBox = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin-bottom: 2px;

  width: 96%;
  height: 24px;

  overflow: hidden;

  border-radius: 5px;
  background-color: #FF8B48;
`;
